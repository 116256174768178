/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type { GenreResponseDto } from "../../../model";
import { axiosInstance } from "../../../config/axios-instance";
import type { ErrorType } from "../../../config/axios-instance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * 장르 조회 (강사 신청 시 조회용)
 * @summary 장르 조회
 */
export const genreControllerGenres = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<GenreResponseDto[]>(
    { url: `/genre`, method: "GET", signal },
    options,
  );
};

export const getGenreControllerGenresQueryKey = () => {
  return [`/genre`] as const;
};

export const getGenreControllerGenresQueryOptions = <
  TData = Awaited<ReturnType<typeof genreControllerGenres>>,
  TError = ErrorType<GenreResponseDto[]>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof genreControllerGenres>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGenreControllerGenresQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof genreControllerGenres>>
  > = ({ signal }) => genreControllerGenres(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof genreControllerGenres>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GenreControllerGenresQueryResult = NonNullable<
  Awaited<ReturnType<typeof genreControllerGenres>>
>;
export type GenreControllerGenresQueryError = ErrorType<GenreResponseDto[]>;

/**
 * @summary 장르 조회
 */
export const useGenreControllerGenres = <
  TData = Awaited<ReturnType<typeof genreControllerGenres>>,
  TError = ErrorType<GenreResponseDto[]>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof genreControllerGenres>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGenreControllerGenresQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
