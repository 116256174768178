/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CourseInDetailResponseDto,
  CreateCourseRequestDto,
  InstructorControllerGetCourseOfInstructorParams,
  InstructorControllerGetCoursesOfInstructorParams,
  InstructorControllerGetEnrolledStudentsParams,
  InstructorControllerGetSchedulesOfInstructorParams,
  InstructorResponseDto,
  PaginatedCoursesResponseDto,
  PaginatedResponseDto,
  RegisterInstructorRequestDto,
  RegisterInvitedInstructorRequestDto,
  ScheduleResponseDto,
  UpdateInstructorRequestDto,
} from "../../../model";
import { axiosInstance } from "../../../config/axios-instance";
import type { ErrorType } from "../../../config/axios-instance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary 강사 지원
 */
export const instructorControllerApplyForInstructor = (
  registerInstructorRequestDto: RegisterInstructorRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<unknown>(
    {
      url: `/instructor/apply`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: registerInstructorRequestDto,
    },
    options,
  );
};

export const getInstructorControllerApplyForInstructorMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof instructorControllerApplyForInstructor>>,
    TError,
    { data: RegisterInstructorRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof instructorControllerApplyForInstructor>>,
  TError,
  { data: RegisterInstructorRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof instructorControllerApplyForInstructor>>,
    { data: RegisterInstructorRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return instructorControllerApplyForInstructor(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InstructorControllerApplyForInstructorMutationResult = NonNullable<
  Awaited<ReturnType<typeof instructorControllerApplyForInstructor>>
>;
export type InstructorControllerApplyForInstructorMutationBody =
  RegisterInstructorRequestDto;
export type InstructorControllerApplyForInstructorMutationError =
  ErrorType<unknown>;

/**
 * @summary 강사 지원
 */
export const useInstructorControllerApplyForInstructor = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof instructorControllerApplyForInstructor>>,
    TError,
    { data: RegisterInstructorRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof instructorControllerApplyForInstructor>>,
  TError,
  { data: RegisterInstructorRequestDto },
  TContext
> => {
  const mutationOptions =
    getInstructorControllerApplyForInstructorMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 학원에서 발급한 초대 토큰 기반 학원 강사 지원
 */
export const instructorControllerApplyForInvitedInstructor = (
  registerInvitedInstructorRequestDto: RegisterInvitedInstructorRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<unknown>(
    {
      url: `/instructor/academy/apply`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: registerInvitedInstructorRequestDto,
    },
    options,
  );
};

export const getInstructorControllerApplyForInvitedInstructorMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof instructorControllerApplyForInvitedInstructor>>,
    TError,
    { data: RegisterInvitedInstructorRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof instructorControllerApplyForInvitedInstructor>>,
  TError,
  { data: RegisterInvitedInstructorRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof instructorControllerApplyForInvitedInstructor>>,
    { data: RegisterInvitedInstructorRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return instructorControllerApplyForInvitedInstructor(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InstructorControllerApplyForInvitedInstructorMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof instructorControllerApplyForInvitedInstructor>>
  >;
export type InstructorControllerApplyForInvitedInstructorMutationBody =
  RegisterInvitedInstructorRequestDto;
export type InstructorControllerApplyForInvitedInstructorMutationError =
  ErrorType<unknown>;

/**
 * @summary 학원에서 발급한 초대 토큰 기반 학원 강사 지원
 */
export const useInstructorControllerApplyForInvitedInstructor = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof instructorControllerApplyForInvitedInstructor>>,
    TError,
    { data: RegisterInvitedInstructorRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof instructorControllerApplyForInvitedInstructor>>,
  TError,
  { data: RegisterInvitedInstructorRequestDto },
  TContext
> => {
  const mutationOptions =
    getInstructorControllerApplyForInvitedInstructorMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * 강의를 생성한다. (강의 초안 저장)
 * @summary 강의 만들기
 */
export const instructorControllerSaveDraftCourse = (
  createCourseRequestDto: CreateCourseRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<CourseInDetailResponseDto>(
    {
      url: `/instructor/course`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createCourseRequestDto,
    },
    options,
  );
};

export const getInstructorControllerSaveDraftCourseMutationOptions = <
  TError = ErrorType<CourseInDetailResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof instructorControllerSaveDraftCourse>>,
    TError,
    { data: CreateCourseRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof instructorControllerSaveDraftCourse>>,
  TError,
  { data: CreateCourseRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof instructorControllerSaveDraftCourse>>,
    { data: CreateCourseRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return instructorControllerSaveDraftCourse(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InstructorControllerSaveDraftCourseMutationResult = NonNullable<
  Awaited<ReturnType<typeof instructorControllerSaveDraftCourse>>
>;
export type InstructorControllerSaveDraftCourseMutationBody =
  CreateCourseRequestDto;
export type InstructorControllerSaveDraftCourseMutationError =
  ErrorType<CourseInDetailResponseDto>;

/**
 * @summary 강의 만들기
 */
export const useInstructorControllerSaveDraftCourse = <
  TError = ErrorType<CourseInDetailResponseDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof instructorControllerSaveDraftCourse>>,
    TError,
    { data: CreateCourseRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof instructorControllerSaveDraftCourse>>,
  TError,
  { data: CreateCourseRequestDto },
  TContext
> => {
  const mutationOptions =
    getInstructorControllerSaveDraftCourseMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * 리퀘스트 쿼리에 language 옵셔널로 추가되었습니다.
 * @summary 강사의 강의 리스트 조회 - GLOBAL EDIT
 */
export const instructorControllerGetCoursesOfInstructor = (
  params?: InstructorControllerGetCoursesOfInstructorParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<PaginatedCoursesResponseDto>(
    { url: `/instructor/course`, method: "GET", params, signal },
    options,
  );
};

export const getInstructorControllerGetCoursesOfInstructorQueryKey = (
  params?: InstructorControllerGetCoursesOfInstructorParams,
) => {
  return [`/instructor/course`, ...(params ? [params] : [])] as const;
};

export const getInstructorControllerGetCoursesOfInstructorQueryOptions = <
  TData = Awaited<
    ReturnType<typeof instructorControllerGetCoursesOfInstructor>
  >,
  TError = ErrorType<PaginatedCoursesResponseDto>,
>(
  params?: InstructorControllerGetCoursesOfInstructorParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof instructorControllerGetCoursesOfInstructor>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getInstructorControllerGetCoursesOfInstructorQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof instructorControllerGetCoursesOfInstructor>>
  > = ({ signal }) =>
    instructorControllerGetCoursesOfInstructor(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof instructorControllerGetCoursesOfInstructor>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InstructorControllerGetCoursesOfInstructorQueryResult = NonNullable<
  Awaited<ReturnType<typeof instructorControllerGetCoursesOfInstructor>>
>;
export type InstructorControllerGetCoursesOfInstructorQueryError =
  ErrorType<PaginatedCoursesResponseDto>;

/**
 * @summary 강사의 강의 리스트 조회 - GLOBAL EDIT
 */
export const useInstructorControllerGetCoursesOfInstructor = <
  TData = Awaited<
    ReturnType<typeof instructorControllerGetCoursesOfInstructor>
  >,
  TError = ErrorType<PaginatedCoursesResponseDto>,
>(
  params?: InstructorControllerGetCoursesOfInstructorParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof instructorControllerGetCoursesOfInstructor>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getInstructorControllerGetCoursesOfInstructorQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 강사의 강의 상세 조회 - GLOBAL EDIT
 */
export const instructorControllerGetCourseOfInstructor = (
  courseId: number,
  params?: InstructorControllerGetCourseOfInstructorParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<CourseInDetailResponseDto>(
    { url: `/instructor/course/${courseId}`, method: "GET", params, signal },
    options,
  );
};

export const getInstructorControllerGetCourseOfInstructorQueryKey = (
  courseId: number,
  params?: InstructorControllerGetCourseOfInstructorParams,
) => {
  return [
    `/instructor/course/${courseId}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getInstructorControllerGetCourseOfInstructorQueryOptions = <
  TData = Awaited<ReturnType<typeof instructorControllerGetCourseOfInstructor>>,
  TError = ErrorType<CourseInDetailResponseDto>,
>(
  courseId: number,
  params?: InstructorControllerGetCourseOfInstructorParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof instructorControllerGetCourseOfInstructor>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getInstructorControllerGetCourseOfInstructorQueryKey(courseId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof instructorControllerGetCourseOfInstructor>>
  > = ({ signal }) =>
    instructorControllerGetCourseOfInstructor(
      courseId,
      params,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!courseId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof instructorControllerGetCourseOfInstructor>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InstructorControllerGetCourseOfInstructorQueryResult = NonNullable<
  Awaited<ReturnType<typeof instructorControllerGetCourseOfInstructor>>
>;
export type InstructorControllerGetCourseOfInstructorQueryError =
  ErrorType<CourseInDetailResponseDto>;

/**
 * @summary 강사의 강의 상세 조회 - GLOBAL EDIT
 */
export const useInstructorControllerGetCourseOfInstructor = <
  TData = Awaited<ReturnType<typeof instructorControllerGetCourseOfInstructor>>,
  TError = ErrorType<CourseInDetailResponseDto>,
>(
  courseId: number,
  params?: InstructorControllerGetCourseOfInstructorParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof instructorControllerGetCourseOfInstructor>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getInstructorControllerGetCourseOfInstructorQueryOptions(
    courseId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * 강사의 스케쥴 리스트 조회한다.
 * @summary 강사의 스케쥴 리스트 조회
 */
export const instructorControllerGetSchedulesOfInstructor = (
  params?: InstructorControllerGetSchedulesOfInstructorParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<ScheduleResponseDto[]>(
    { url: `/instructor/schedule`, method: "GET", params, signal },
    options,
  );
};

export const getInstructorControllerGetSchedulesOfInstructorQueryKey = (
  params?: InstructorControllerGetSchedulesOfInstructorParams,
) => {
  return [`/instructor/schedule`, ...(params ? [params] : [])] as const;
};

export const getInstructorControllerGetSchedulesOfInstructorQueryOptions = <
  TData = Awaited<
    ReturnType<typeof instructorControllerGetSchedulesOfInstructor>
  >,
  TError = ErrorType<ScheduleResponseDto[]>,
>(
  params?: InstructorControllerGetSchedulesOfInstructorParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof instructorControllerGetSchedulesOfInstructor>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getInstructorControllerGetSchedulesOfInstructorQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof instructorControllerGetSchedulesOfInstructor>>
  > = ({ signal }) =>
    instructorControllerGetSchedulesOfInstructor(
      params,
      requestOptions,
      signal,
    );

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof instructorControllerGetSchedulesOfInstructor>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InstructorControllerGetSchedulesOfInstructorQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof instructorControllerGetSchedulesOfInstructor>>
  >;
export type InstructorControllerGetSchedulesOfInstructorQueryError = ErrorType<
  ScheduleResponseDto[]
>;

/**
 * @summary 강사의 스케쥴 리스트 조회
 */
export const useInstructorControllerGetSchedulesOfInstructor = <
  TData = Awaited<
    ReturnType<typeof instructorControllerGetSchedulesOfInstructor>
  >,
  TError = ErrorType<ScheduleResponseDto[]>,
>(
  params?: InstructorControllerGetSchedulesOfInstructorParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof instructorControllerGetSchedulesOfInstructor>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getInstructorControllerGetSchedulesOfInstructorQueryOptions(
      params,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * 특정 사이클의 수강생 리스트를 조회한다.
 * @summary 특정 사이클의 수강생 리스트 조회 (with 페이지네이션)
 */
export const instructorControllerGetEnrolledStudents = (
  cycleId: number,
  params?: InstructorControllerGetEnrolledStudentsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<PaginatedResponseDto>(
    {
      url: `/instructor/cycle/${cycleId}/enrollment`,
      method: "GET",
      params,
      signal,
    },
    options,
  );
};

export const getInstructorControllerGetEnrolledStudentsQueryKey = (
  cycleId: number,
  params?: InstructorControllerGetEnrolledStudentsParams,
) => {
  return [
    `/instructor/cycle/${cycleId}/enrollment`,
    ...(params ? [params] : []),
  ] as const;
};

export const getInstructorControllerGetEnrolledStudentsQueryOptions = <
  TData = Awaited<ReturnType<typeof instructorControllerGetEnrolledStudents>>,
  TError = ErrorType<PaginatedResponseDto>,
>(
  cycleId: number,
  params?: InstructorControllerGetEnrolledStudentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof instructorControllerGetEnrolledStudents>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getInstructorControllerGetEnrolledStudentsQueryKey(cycleId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof instructorControllerGetEnrolledStudents>>
  > = ({ signal }) =>
    instructorControllerGetEnrolledStudents(
      cycleId,
      params,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!cycleId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof instructorControllerGetEnrolledStudents>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InstructorControllerGetEnrolledStudentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof instructorControllerGetEnrolledStudents>>
>;
export type InstructorControllerGetEnrolledStudentsQueryError =
  ErrorType<PaginatedResponseDto>;

/**
 * @summary 특정 사이클의 수강생 리스트 조회 (with 페이지네이션)
 */
export const useInstructorControllerGetEnrolledStudents = <
  TData = Awaited<ReturnType<typeof instructorControllerGetEnrolledStudents>>,
  TError = ErrorType<PaginatedResponseDto>,
>(
  cycleId: number,
  params?: InstructorControllerGetEnrolledStudentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof instructorControllerGetEnrolledStudents>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getInstructorControllerGetEnrolledStudentsQueryOptions(
    cycleId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 강사 정보 조회(with token) - DEPRECATED
 */
export const instructorControllerGetInstructorProfile = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<InstructorResponseDto>(
    { url: `/instructor/profile`, method: "GET", signal },
    options,
  );
};

export const getInstructorControllerGetInstructorProfileQueryKey = () => {
  return [`/instructor/profile`] as const;
};

export const getInstructorControllerGetInstructorProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof instructorControllerGetInstructorProfile>>,
  TError = ErrorType<InstructorResponseDto>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof instructorControllerGetInstructorProfile>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getInstructorControllerGetInstructorProfileQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof instructorControllerGetInstructorProfile>>
  > = ({ signal }) =>
    instructorControllerGetInstructorProfile(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof instructorControllerGetInstructorProfile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InstructorControllerGetInstructorProfileQueryResult = NonNullable<
  Awaited<ReturnType<typeof instructorControllerGetInstructorProfile>>
>;
export type InstructorControllerGetInstructorProfileQueryError =
  ErrorType<InstructorResponseDto>;

/**
 * @summary 강사 정보 조회(with token) - DEPRECATED
 */
export const useInstructorControllerGetInstructorProfile = <
  TData = Awaited<ReturnType<typeof instructorControllerGetInstructorProfile>>,
  TError = ErrorType<InstructorResponseDto>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof instructorControllerGetInstructorProfile>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getInstructorControllerGetInstructorProfileQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 강사 수정 - DEPRECATED
 */
export const instructorControllerUpdateInstructorProfile = (
  updateInstructorRequestDto: UpdateInstructorRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<unknown>(
    {
      url: `/instructor/profile`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateInstructorRequestDto,
    },
    options,
  );
};

export const getInstructorControllerUpdateInstructorProfileMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof instructorControllerUpdateInstructorProfile>>,
    TError,
    { data: UpdateInstructorRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof instructorControllerUpdateInstructorProfile>>,
  TError,
  { data: UpdateInstructorRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof instructorControllerUpdateInstructorProfile>>,
    { data: UpdateInstructorRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return instructorControllerUpdateInstructorProfile(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InstructorControllerUpdateInstructorProfileMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof instructorControllerUpdateInstructorProfile>>
  >;
export type InstructorControllerUpdateInstructorProfileMutationBody =
  UpdateInstructorRequestDto;
export type InstructorControllerUpdateInstructorProfileMutationError =
  ErrorType<unknown>;

/**
 * @summary 강사 수정 - DEPRECATED
 */
export const useInstructorControllerUpdateInstructorProfile = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof instructorControllerUpdateInstructorProfile>>,
    TError,
    { data: UpdateInstructorRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof instructorControllerUpdateInstructorProfile>>,
  TError,
  { data: UpdateInstructorRequestDto },
  TContext
> => {
  const mutationOptions =
    getInstructorControllerUpdateInstructorProfileMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 강사 정보 조회(with token) - GLOBAL NEW
 */
export const instructorControllerGetInstructorProfileGlobal = (
  lang: "ko" | "en",
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<InstructorResponseDto>(
    { url: `/instructor/profile/${lang}`, method: "GET", signal },
    options,
  );
};

export const getInstructorControllerGetInstructorProfileGlobalQueryKey = (
  lang: "ko" | "en",
) => {
  return [`/instructor/profile/${lang}`] as const;
};

export const getInstructorControllerGetInstructorProfileGlobalQueryOptions = <
  TData = Awaited<
    ReturnType<typeof instructorControllerGetInstructorProfileGlobal>
  >,
  TError = ErrorType<InstructorResponseDto>,
>(
  lang: "ko" | "en",
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof instructorControllerGetInstructorProfileGlobal>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getInstructorControllerGetInstructorProfileGlobalQueryKey(lang);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof instructorControllerGetInstructorProfileGlobal>>
  > = ({ signal }) =>
    instructorControllerGetInstructorProfileGlobal(
      lang,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!lang,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof instructorControllerGetInstructorProfileGlobal>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InstructorControllerGetInstructorProfileGlobalQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof instructorControllerGetInstructorProfileGlobal>>
  >;
export type InstructorControllerGetInstructorProfileGlobalQueryError =
  ErrorType<InstructorResponseDto>;

/**
 * @summary 강사 정보 조회(with token) - GLOBAL NEW
 */
export const useInstructorControllerGetInstructorProfileGlobal = <
  TData = Awaited<
    ReturnType<typeof instructorControllerGetInstructorProfileGlobal>
  >,
  TError = ErrorType<InstructorResponseDto>,
>(
  lang: "ko" | "en",
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof instructorControllerGetInstructorProfileGlobal>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getInstructorControllerGetInstructorProfileGlobalQueryOptions(
      lang,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 강사 수정 - GLOBAL NEW
 */
export const instructorControllerUpdateInstructorProfileGlobal = (
  lang: "ko" | "en",
  updateInstructorRequestDto: UpdateInstructorRequestDto,
  options?: SecondParameter<typeof axiosInstance>,
) => {
  return axiosInstance<unknown>(
    {
      url: `/instructor/profile/${lang}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateInstructorRequestDto,
    },
    options,
  );
};

export const getInstructorControllerUpdateInstructorProfileGlobalMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof instructorControllerUpdateInstructorProfileGlobal>
      >,
      TError,
      { lang: "ko" | "en"; data: UpdateInstructorRequestDto },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof instructorControllerUpdateInstructorProfileGlobal>
    >,
    TError,
    { lang: "ko" | "en"; data: UpdateInstructorRequestDto },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof instructorControllerUpdateInstructorProfileGlobal>
      >,
      { lang: "ko" | "en"; data: UpdateInstructorRequestDto }
    > = (props) => {
      const { lang, data } = props ?? {};

      return instructorControllerUpdateInstructorProfileGlobal(
        lang,
        data,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type InstructorControllerUpdateInstructorProfileGlobalMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof instructorControllerUpdateInstructorProfileGlobal>
    >
  >;
export type InstructorControllerUpdateInstructorProfileGlobalMutationBody =
  UpdateInstructorRequestDto;
export type InstructorControllerUpdateInstructorProfileGlobalMutationError =
  ErrorType<unknown>;

/**
 * @summary 강사 수정 - GLOBAL NEW
 */
export const useInstructorControllerUpdateInstructorProfileGlobal = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof instructorControllerUpdateInstructorProfileGlobal>
    >,
    TError,
    { lang: "ko" | "en"; data: UpdateInstructorRequestDto },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof instructorControllerUpdateInstructorProfileGlobal>>,
  TError,
  { lang: "ko" | "en"; data: UpdateInstructorRequestDto },
  TContext
> => {
  const mutationOptions =
    getInstructorControllerUpdateInstructorProfileGlobalMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};
