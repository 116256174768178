/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * KAC-API
 * <b>K-Artist Class API<b/>
 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  FileControllerGetSignedUrlParams,
  SignedUrlResponseDto,
} from "../../../model";
import { axiosInstance } from "../../../config/axios-instance";
import type { ErrorType } from "../../../config/axios-instance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary 프리사인 url 가져오기
 */
export const fileControllerGetSignedUrl = (
  params: FileControllerGetSignedUrlParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<SignedUrlResponseDto>(
    { url: `/file/signed-url`, method: "GET", params, signal },
    options,
  );
};

export const getFileControllerGetSignedUrlQueryKey = (
  params: FileControllerGetSignedUrlParams,
) => {
  return [`/file/signed-url`, ...(params ? [params] : [])] as const;
};

export const getFileControllerGetSignedUrlQueryOptions = <
  TData = Awaited<ReturnType<typeof fileControllerGetSignedUrl>>,
  TError = ErrorType<SignedUrlResponseDto>,
>(
  params: FileControllerGetSignedUrlParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fileControllerGetSignedUrl>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFileControllerGetSignedUrlQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fileControllerGetSignedUrl>>
  > = ({ signal }) =>
    fileControllerGetSignedUrl(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fileControllerGetSignedUrl>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FileControllerGetSignedUrlQueryResult = NonNullable<
  Awaited<ReturnType<typeof fileControllerGetSignedUrl>>
>;
export type FileControllerGetSignedUrlQueryError =
  ErrorType<SignedUrlResponseDto>;

/**
 * @summary 프리사인 url 가져오기
 */
export const useFileControllerGetSignedUrl = <
  TData = Awaited<ReturnType<typeof fileControllerGetSignedUrl>>,
  TError = ErrorType<SignedUrlResponseDto>,
>(
  params: FileControllerGetSignedUrlParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fileControllerGetSignedUrl>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFileControllerGetSignedUrlQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
